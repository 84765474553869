import axios from "axios";
import HttpClient from "./http-client";

// const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `/verify_token`;
export const LOGIN_URL = `/auth/admin/login`;
export const REGISTER_URL = `/register`;
export const REQUEST_PASSWORD_URL = `/forgot_password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return HttpClient.post(LOGIN_URL, { email, password });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getAdminByEmail(email: string) {
  return HttpClient.get(`/admins/${email}`);
}
