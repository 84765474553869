// import React from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import LandingPage from "./pages/landing/LandingPage";
// import Dashboard from "./pages/dashboard/Dashboard";
// import Counter from "./pages/counter/Counter";

// function App() {
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<LandingPage />}></Route>
//           <Route path={"/dashboard"} element={<Dashboard />}></Route>
//           <Route path={"/counter"} element={<Counter />}></Route>
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;

import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "./_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "./_metronic/layout/core";
import { MasterInit } from "./_metronic/layout/MasterInit";
import { AuthInit } from "./pages/auth";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
