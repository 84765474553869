import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import QueriesServices from "../../../services/queries";
import error from "../../../utils/error";

// export interface ViewDevqueriesInterface {
//   email: string;
//   id: number;
//   name: string;
//   phoneno: string;
//   status: string;
// }
interface ViewDevqueriesByidState {
  data?: [] | any;
  loading: boolean;
  error?: string;
}

const initialState: ViewDevqueriesByidState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export const ViewDevqueriesByid = createAsyncThunk(
  "ViewDevqueriesByid/ViewDevqueries",
  async (id: any) => {
    try {
      const res = await QueriesServices.ViewDevqueriesByid(id);
      console.log(res.data);
      return res.data;
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "ViewDevqueriesByid",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(ViewDevqueriesByid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ViewDevqueriesByid.fulfilled, (state, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(ViewDevqueriesByid.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
