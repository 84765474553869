export default class FrontEndRoutes {
  public static ADD_USER = "/user/create";
  public static EDIT_USER = "/user/edit";
  public static USER_MANAGEMENT = "/user";
  public static BLOG = "/blog";
  public static Add_BLOG = "/blog/add";
  public static VIEW_BLOG = "/blog/view";
  public static CONTACT_QUERIES = "/queries";
  public static DEVELOPER_QUERIES = "/queries/dev";
  public static VISITOR_QUERIES = "/queries/visitor";
  public static QUERIES_RESPONSE = "/queries/dev/res";
  public static QUERIES_RESPONSE_V = "/queries/visitor/res";

  public static SUBSCRIPTION_MANAGEMENT = "/subscription";
  public static ADD_SUBSCRIPTION = "/subscription/add";
  public static FREE_SUBSCRIPTION = "/subscription/free";
  public static STARTER_SUBSCRIPTION = "/subscription/starter";
  public static PRO_SUBSCRIPTION = "/subscription/pro";
  public static PAYMENT_MANAGEMENT = "/payment";
  public static DOCUMENT_MANAGEMENT = "/document";
  public static MY_ACCOUNT = "/profile";
  public static RESET_PASSWORD = "/profile/reset";
  public static DASHBOARD_WRAPPER = "/dashboard";
  public static INTEGRATIONGUIDE = "/document/integrationguide";
  public static TUTORIALDETAILS = "/document/tutorialdetails";
  public static APIREFERENCE = "/document/apireference";
  public static SAMPLECODE = "/document/samplecode";
  public static SDKS = "/document/sdks";
  public static DESIGN = "/document/design";
  public static PAYMENT_DETAILS = "/payment/id";
  public static TALK_TO_US_REQUEST = "/request";
  public static REQUEST_VIEW_DETAIL = "/request/view";
  public static ADD_DOCS_DETAILS = "/document/add";
  public static EDIT_DOCS_DETAILS = "/document/id";
  public static ABOUT_US = "/about";
  public static CONTANT_US = "/contant";
  public static WHO_WE_ARE = "/whoweare";
  public static TERM_AND_CONDITION = "/term_condition";
  public static SKITTYBOP = "/skittybop";
  public static PRIVACYPOLICY = "/privacypolicy";
}
