import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RequestServices from "../../../services/request-services";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface viewRequestState {
  data?: [] | any;
  loading: boolean;
  error?: string;
}

const initialState: viewRequestState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export const viewRequest = createAsyncThunk(
  "ViewRequest/ViewRequestDetails",
  async (data: string) => {
    try {
      const res = await RequestServices.viewRequest(data);
      console.log(res);
      return res.data;
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "viewRequest",
  initialState,
  reducers: {
    resetviewRequest: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(viewRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewRequest.fulfilled, (state, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(viewRequest.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});
export const { resetviewRequest } = slice.actions;

export default slice.reducer;
