import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminServices from "../../../services/admin";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface AddUserState {
  isAdded: boolean;
  loading: boolean;
  error?: string;
}

const initialState: AddUserState = {
  isAdded: false,
  loading: false,
  error: undefined,
};

interface IThunk {
  value: any;
}
export const addUser = createAsyncThunk(
  "addUser/user",
  async (data: IThunk) => {
    try {
      await AdminServices.addUser(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    resetaddUser: (state) => {
      state.loading = false;
      state.isAdded = false;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUser.fulfilled, (state) => {
      state.loading = false;
      state.isAdded = true;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
export const { resetaddUser } = slice.actions;
