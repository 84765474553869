import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BlogServices from "../../../services/blog-services";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface AddBlogState {
  isAdded: boolean;
  loading: boolean;
  error?: string;
}

const initialState: AddBlogState = {
  isAdded: false,
  loading: false,
  error: undefined,
};

interface IThunk {
  key: string;
  value: any;
}
export const addBlog = createAsyncThunk(
  "addBlog/blogDetails",
  async (data: IThunk) => {
    try {
      return BlogServices.addBlog(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "addBlog",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(addBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBlog.fulfilled, (state) => {
      state.loading = false;
      state.isAdded = true;
    });
    builder.addCase(addBlog.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
