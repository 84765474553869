// import { IQuery } from "../store/user/view-user/slice";
import HttpClient from "./http-client";
// export const USER_URL = (data: IQuery) => {
//   if (data) return `/users?name=${data.name}`;
//   return `/users`;
// };
export const DEV_QUERY_URL = `/developer-query`;

export const CRUD_QUERY_URL = `/developer-query/`;
export const USER_URL_CRUD = `/users/`;
export default class QueriesServices {
  // public static readonly EXAMPLE = "EXAMPLE URL"

  public static async viewDevqueries() {
    return HttpClient.get(DEV_QUERY_URL);
  }

  public static async deleteDevqueries(data: any) {
    console.log(data);
    return HttpClient.delete(CRUD_QUERY_URL + data);
  }

  public static async ViewDevqueriesByid(id: any) {
    return HttpClient.get(CRUD_QUERY_URL + id);
  }
  // public static async editUser(data: any) {
  //   console.log(data);
  //   const { value } = data;
  //   const { id } = value;
  //   delete value.id;
  //   return await HttpClient.patch(USER_URL_CRUD + id, value);
  // }
}
