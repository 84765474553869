import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosResponseTransformer,
} from "axios";

export default class HttpClient {
  // Here you can use your server URL
  private static readonly baseURL = "http://api-skittybop2.php-dev.in:3146/api";
  // private static readonly baseURL: string = "http://localhost:3146/api";
  // private static readonly baseURL = process.env.REACT_APP_API_URL;
  private static buildHeader(obj = {}): AxiosRequestHeaders {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    };
    Object.assign(header, obj);

    return header;
  }

  private static transformResponse(
    input: string
  ): AxiosResponseTransformer | AxiosResponseTransformer[] {
    return JSON.parse(input);
  }

  private static client(header = {}): AxiosInstance {
    // cancelToken and source declaration
    const cancelTokenSource = axios.CancelToken.source();

    // axios client config
    const config: AxiosRequestConfig = {
      baseURL: this.baseURL,
      cancelToken: cancelTokenSource.token,
      headers: this.buildHeader(header),
    };

    // axios client response transformer
    config.transformResponse = [
      (data) => {
        return data && typeof data === "string"
          ? this.transformResponse(data)
          : data;
      },
    ];

    // create axios client
    const instanse = axios.create(config);
    instanse.interceptors.response.use(
      (res) => res,
      async (error) => {
        const originalConfigs = error.config;

        if (
          error.response.status === 401 &&
          localStorage.getItem("access_token") &&
          localStorage.getItem("refresh_token") &&
          !originalConfigs._retry
        ) {
          console.log("data fetch");
          const response = await axios.request({
            method: "POST",
            baseURL: this.baseURL,
            url: "/auth/admin/refresh-access-token",
            data: {
              refreshToken: localStorage.getItem("refresh_token"),
            },
          });

          if (response.status === 200) {
            const { access_token, refresh_token } = response.data;
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("refresh_token", refresh_token);

            originalConfigs.headers = this.buildHeader({ _retry: true });

            return axios.request(originalConfigs);
          }
        }
        throw error;
      }
    );
    return instanse;
  }

  /**
   *
   * @param url
   * @returns
   */
  public static get(url: string): Promise<AxiosResponse> {
    return this.client().get(url);
  }

  /**
   *
   * @param url
   * @param payload
   * @returns
   */
  public static post<T>(
    url: string,
    payload: T,
    options?: {}
  ): Promise<AxiosResponse> {
    return this.client(options).post(url, payload);
  }

  public static patch<T>(url: string, payload: T): Promise<AxiosResponse> {
    return this.client().patch(url, payload);
  }

  public static put<T>(url: string, payload: T): Promise<AxiosResponse> {
    return this.client().put(url, payload);
  }

  public static delete(url: string, data?: any): Promise<AxiosResponse> {
    return this.client().delete(url, { data });
  }
}
