import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RequestServices from "../../../services/request-services";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface ViewRequestState {
  data?: [] | any;
  loading: boolean;
  error?: string;
}

const initialState: ViewRequestState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export const viewRequestById = createAsyncThunk(
  "ViewRequestId/viewRequestById",
  async (id: any) => {
    try {
      const res = await RequestServices.viewRequestById(id);
      console.log(res);
      return res.data;
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "viewRequestById",
  initialState,
  reducers: {
    resetviewRequestByid: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(viewRequestById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewRequestById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(viewRequestById.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});
export const { resetviewRequestByid } = slice.actions;

export default slice.reducer;
