import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminServices from "../../../services/admin";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface DeleteUserState {
  isDeleted: boolean;
  loading: boolean;
  error?: string;
}

const initialState: DeleteUserState = {
  isDeleted: false,
  loading: false,
  error: undefined,
};

interface IThunk {
  id: string | any;
}
export const deleteUser = createAsyncThunk(
  "deleteUser/deleteUser",
  async (data: IThunk) => {
    try {
      return await AdminServices.deleteUser(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "deleteUser",
  initialState,
  reducers: {
    resetdeleteUser: (state) => {
      state.loading = false;
      state.isDeleted = false;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.loading = false;
      state.isDeleted = true;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});
export const { resetdeleteUser } = slice.actions;
export default slice.reducer;
