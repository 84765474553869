import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import landingReducer from "./landing/slice";
import addBlogReducer from "./blog/add-blog/slice";
import viewBlogReducer from "./blog/view-blog/slice";
import deleteBlogReducer from "./blog/delete-blog/slice";
import counterReducer from "./counter/slice";
import addUserReducer from "./user/add-user/slice";
import viewUserReducer from "./user/view-user/slice";
import deleteUserReducer from "./user/delete-user/slice";
import editUserReducer from "./user/edit-user/slice";
import viewRequestReducer from "./talktousrequest/view-request/slice";
import deleteRequestReducer from "./talktousrequest/delete-request/slice";
import viewPaymentReducer from "./payment/view-payment/slice";
import deletePaymentReducer from "./payment/delete-payment/slice";
import viewPaymentByIdReducer from "./payment/view-payment-id/slice";
import viewRequestByIdReducer from "./talktousrequest/view-request-id/slice";
import viewDevqueriesReducer from "./queries/view-devqueries/slice";
import deleteDevqueriesReducer from "./queries/delete-devqueries/slice";
import editUserStatusReducer from "./user/edit-user-status/slice";
import ViewDevqueriesByidReducer from "./queries/view-devqueriesByid/slice";

const store = configureStore({
  reducer: {
    landing: landingReducer,
    addBlog: addBlogReducer,
    viewBlog: viewBlogReducer,
    deleteBlog: deleteBlogReducer,
    counter: counterReducer,
    addUser: addUserReducer,
    viewUser: viewUserReducer,
    deleteUser: deleteUserReducer,
    editUser: editUserReducer,
    viewRequest: viewRequestReducer,
    deleteRequest: deleteRequestReducer,
    viewPayment: viewPaymentReducer,
    deletePayment: deletePaymentReducer,
    viewPaymentById: viewPaymentByIdReducer,
    viewRequestById: viewRequestByIdReducer,
    viewDevqueries: viewDevqueriesReducer,
    deleteDevqueries: deleteDevqueriesReducer,
    editUserStatus: editUserStatusReducer,
    ViewDevqueriesByid: ViewDevqueriesByidReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;
