import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import QueriesServices from "../../../services/queries";
import error from "../../../utils/error";

export interface DeleteDevqueriesState {
  isDeleted: boolean;
  loading: boolean;
  error?: string;
}

const initialState: DeleteDevqueriesState = {
  isDeleted: false,
  loading: false,
  error: undefined,
};

interface IThunk {
  id: string;
}
export const deleteDevqueries = createAsyncThunk(
  "deleteDevqueries/deleteDevqueries",
  async (data: IThunk) => {
    try {
      return await QueriesServices.deleteDevqueries(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "deleteRequest",
  initialState,
  reducers: {
    resetdeleteDevqueries: (state) => {
      state.loading = false;
      state.isDeleted = false;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(deleteDevqueries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDevqueries.fulfilled, (state) => {
      state.loading = false;
      state.isDeleted = true;
    });
    builder.addCase(deleteDevqueries.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});
export const { resetdeleteDevqueries } = slice.actions;
export default slice.reducer;
