/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import FrontEndRoutes from "../../../routing/frontend-routes";
import { useLayout } from "../core";
import { Link } from "react-router-dom";

const Footer: FC = () => {
  const { classes } = useLayout();
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          <a href="#" className="text-gray-800 text-hover-primary">
            SkittyBop
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a className="menu-link ps-0 pe-2">
              <Link to={FrontEndRoutes.ABOUT_US}>About</Link>
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0 pe-2">
              <Link to={FrontEndRoutes.CONTANT_US}>Contact</Link>
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0">
              <Link to={FrontEndRoutes.WHO_WE_ARE}> Who we are </Link>
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0">
              <Link to={FrontEndRoutes.TERM_AND_CONDITION}>
                Term & condition{" "}
              </Link>
            </a>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
