import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminServices from "../../../services/admin";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface EditUserstatusState {
  isEdited: boolean;
  loading: boolean;
  error?: string;
}

const initialState: EditUserstatusState = {
  isEdited: false,
  loading: false,
  error: undefined,
};

interface IThunk {
  status: any;
  id: any;
  // stateData: any;
}
export const editUserStatus = createAsyncThunk(
  "editUserStatus/editUserStatus",
  async (data: IThunk) => {
    try {
      return AdminServices.editUserStatus(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "editUserStatus",
  initialState,
  reducers: {
    reseteditUserStatus: (state) => {
      state.loading = false;
      state.isEdited = false;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(editUserStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editUserStatus.fulfilled, (state) => {
      state.loading = false;
      state.isEdited = true;
    });
    builder.addCase(editUserStatus.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});
export const { reseteditUserStatus } = slice.actions;

export default slice.reducer;
