import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminServices from "../../../services/admin";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface EditUserState {
  isEdited: boolean;
  loading: boolean;
  error?: string;
}

const initialState: EditUserState = {
  isEdited: false,
  loading: false,
  error: undefined,
};

interface IThunk {
  value: any;
  // stateData: any;
}
export const editUser = createAsyncThunk(
  "editUser/user",
  async (data: IThunk) => {
    try {
      return AdminServices.editUser(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "editUser",
  initialState,
  reducers: {
    reseteditUser: (state) => {
      state.loading = false;
      state.isEdited = false;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(editUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editUser.fulfilled, (state) => {
      state.loading = false;
      state.isEdited = true;
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});
export const { reseteditUser } = slice.actions;

export default slice.reducer;
