import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BlogServices from "../../../services/blog-services";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

interface ViewDataInterface {
  category: string;
  date: string;
  description: string;
  id: number;
  image: any;
  title: string;
}
interface ViewBlogState {
  data?: ViewDataInterface[];
  loading: boolean;
  error?: string;
}

const initialState: ViewBlogState = {
  data: undefined,
  loading: false,
  error: undefined,
};

// interface IThunk {
//   key: string;
//   value: any;
// }
export const viewBlog = createAsyncThunk(
  "viewBlog/viewBlogDetails",
  async (data: string) => {
    try {
      return BlogServices.viewBlog(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "viewBlog",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(viewBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewBlog.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(viewBlog.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
