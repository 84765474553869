import React, { lazy } from "react";
import Dashboard from "../pages/dashboard/Dashboard";

const UserManagement = lazy(() => import("../pages/user_management"));
const AddUser = lazy(() => import("../pages/user_management/AddUser"));
const EditUser = lazy(() => import("../pages/user_management/EditUser"));

const Manageblog = lazy(() => import("../pages/manage_blog"));
const AddBlog = lazy(() => import("../pages/manage_blog/AddBlog"));

const IntegrationGuide = lazy(
  () => import("../pages/document_management/Integrationguide")
);
const TutorialDetails = lazy(
  () => import("../pages/document_management/TutorialDetails")
);
const ApiReference = lazy(
  () => import("../pages/document_management/ApiReference")
);
const Design = lazy(() => import("../pages/document_management/Design"));
const SampleCode = lazy(
  () => import("../pages/document_management/SampleCode")
);
const SDKs = lazy(() => import("../pages/document_management/SDKs"));
const EditBlog = lazy(() => import("../pages/manage_blog/EditBlog"));

const SubscriptionManagement = lazy(
  () => import("../pages/subscription_management")
);
const FreeSub = lazy(() => import("../pages/subscription_management/FreeSub"));
const ProSub = lazy(() => import("../pages/subscription_management/ProSub"));
const StarterSub = lazy(
  () => import("../pages/subscription_management/StarterSub")
);
const AddPlan = lazy(() => import("../pages/subscription_management/AddPlan"));
const DocumentManagement = lazy(
  () => import("../pages/document_management/index")
);
const AddDetails = lazy(
  () => import("../pages/document_management/AddDetails")
);
const EditDetails = lazy(
  () => import("../pages/document_management/EditDetails")
);
const MyAccount = lazy(() => import("../pages/my_account/MyAccount"));
const ResetPassword = lazy(() => import("../pages/my_account/ResetPassword"));

const PaymentManagement = lazy(
  () => import("../pages/payment_management/index")
);
const PaymentDetails = lazy(
  () => import("../pages/payment_management/PaymentDetails")
);
const ContactQueries = lazy(() => import("../pages/contact_queries/index"));
const Response = lazy(() => import("../pages/contact_queries/Response"));

const Developer_queries = lazy(
  () => import("../pages/contact_queries/Developer_queries")
);

const Visitor_queries = lazy(
  () => import("../pages/contact_queries/Visitor_queries")
);
const Aboutus = lazy(() => import("../pages/static_pages/Aboutus"));
const Whoweare = lazy(() => import("../pages/static_pages/Who_we_are"));
const SkittyBop = lazy(() => import("../pages/static_pages/SkittyBop"));
const PrivacyPolicy = lazy(() => import("../pages/static_pages/Privacypolicy"));

const Term_condition = lazy(
  () => import("../pages/static_pages/Terms_condition")
);
const Contantus = lazy(() => import("../pages/static_pages/Contant_us"));

const Talktousrequest = lazy(() => import("../pages/talk_to_us"));
const RequestViewDetails = lazy(
  () => import("../pages/talk_to_us/RequestViewDetails")
);
export interface IRoutes {
  path: string;
  element: React.FC & IRoutes[];
}

export const routes = [
  {
    path: "user",
    element: [
      {
        path: "",
        element: UserManagement,
      },
      {
        path: "create",
        element: AddUser,
      },
      {
        path: "edit",
        element: EditUser,
      },
    ],
  },
  {
    path: "blog",
    element: [
      {
        path: "",
        element: Manageblog,
      },
      {
        path: "add",
        element: AddBlog,
      },

      {
        path: "view",
        element: EditBlog,
      },
    ],
  },
  {
    path: "queries",

    element: [
      {
        path: "",
        element: ContactQueries,
      },
      {
        path: "dev",

        element: [
          {
            path: "",
            element: Developer_queries,
          },
          {
            path: "res",
            element: Response,
          },
        ],
      },
      {
        path: "visitor",

        element: [
          {
            path: "",
            element: Visitor_queries,
          },
          {
            path: "res",
            element: Response,
          },
        ],
      },
    ],
  },
  {
    path: "subscription",
    element: [
      {
        path: "",
        element: SubscriptionManagement,
      },
      {
        path: "add",
        element: AddPlan,
      },
      {
        path: "free",
        element: FreeSub,
      },

      {
        path: "starter",
        element: StarterSub,
      },
      {
        path: "pro",
        element: ProSub,
      },
    ],
  },

  {
    path: "payment",
    element: [
      {
        path: "",
        element: PaymentManagement,
      },
      {
        path: "id",
        element: PaymentDetails,
      },
    ],
  },

  {
    path: "document",
    element: [
      {
        path: "",
        element: DocumentManagement,
      },
      {
        path: "add",
        element: AddDetails,
      },
      {
        path: "id",
        element: EditDetails,
      },
      {
        path: "integrationguide",
        element: IntegrationGuide,
      },
      {
        path: "tutorialdetails",
        element: TutorialDetails,
      },
      {
        path: "apireference",
        element: ApiReference,
      },
      {
        path: "samplecode",
        element: SampleCode,
      },
      {
        path: "design",
        element: Design,
      },
      {
        path: "sdks",
        element: SDKs,
      },
    ],
  },
  {
    path: "profile",
    element: [
      {
        path: "",
        element: MyAccount,
      },
      {
        path: "reset",
        element: ResetPassword,
      },
    ],
  },
  {
    path: "about",
    element: Aboutus,
  },
  {
    path: "whoweare",
    element: Whoweare,
  },
  {
    path: "term_condition",
    element: Term_condition,
  },
  {
    path: "contant",
    element: Contantus,
  },
  {
    path: "skittybop",
    element: SkittyBop,
  },
  {
    path: "privacypolicy",
    element: PrivacyPolicy,
  },
  {
    path: "dashboard",
    element: Dashboard,
  },
  {
    path: "request",
    element: [
      {
        path: "",
        element: Talktousrequest,
      },
      {
        path: "view",
        element: RequestViewDetails,
      },
    ],
  },
];
