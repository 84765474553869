import HttpClient from "./http-client";
export const REQUEST_URL = `/call-request`;
export const REQUEST_URL_BY_ID = `/call-request/`;
export default class RequestServices {
  // public static readonly EXAMPLE = "EXAMPLE URL"

  public static async viewRequest(data: any) {
    return HttpClient.get(REQUEST_URL);
  }

  public static async viewRequestById(id: any) {
    return HttpClient.get(REQUEST_URL_BY_ID + id);
  }

  public static async deleteRequest(data: any) {
    return HttpClient.delete(REQUEST_URL, data);
  }
}
