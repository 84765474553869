export default class BlogServices {
  // public static readonly EXAMPLE = "EXAMPLE URL"

  public static async addBlog(data: any) {
    localStorage.setItem(data.key, JSON.stringify(data.value));
  }

  public static async viewBlog(key: string) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  }

  public static async deleteBlog(data: any) {
    const data1 = localStorage.getItem(data.key);
    const objData = data1 ? JSON.parse(data1) : [];
    const fiilteredData = objData.filter((o: any) => o.title !== data.title);
    localStorage.setItem("posts", JSON.stringify(fiilteredData));
  }
}
