// import { IQuery } from "../store/user/view-user/slice";
import HttpClient from "./http-client";
// export const USER_URL = (data: IQuery) => {
//   if (data) return `/users?name=${data.name}`;
//   return `/users`;
// };
export const USER_URL = `/users`;

export const ADDUSER_URL = `/users`;
export const USER_URL_CRUD = `/users/`;
export default class AdminServices {
  // public static readonly EXAMPLE = "EXAMPLE URL"

  public static async addUser(data: any) {
    const { value } = data;
    return await HttpClient.post(ADDUSER_URL, value);
  }

  public static async viewUser(query: string = "") {
    return HttpClient.get(USER_URL + `?${query}`);
  }

  public static async deleteUser(data: any) {
    console.log(data);
    return HttpClient.delete(USER_URL_CRUD + data.id);
  }

  public static async editUser(data: any) {
    console.log(data);
    const { value } = data;
    const { id } = value;
    delete value.id;
    return await HttpClient.patch(USER_URL_CRUD + id, value);
  }

  public static async editUserStatus(data: any) {
    console.log(data);
    const { status, id } = data;
    // const { id } = value;
    console.log(status);
    console.log(id);

    delete data.id;
    return await HttpClient.patch(USER_URL_CRUD + id + "/update-status", data);
  }
}
