import FrontEndRoutes from "../../routing/frontend-routes";

export interface ISidemenu {
  to: string;
  icon: string;
  title: string;
  fontIcon: string;
  submenu?: ISidemenu[];
}
export const menu = [
  {
    to: "/dashboard",
    icon: "/media/icons/duotune/art/dash.svg",
    title: "MENU.DASHBOARD",
    fontIcon: "bi-app-indicator",
  },
  {
    to: "/user",
    icon: "/media/icons/duotune/art/user.svg",
    title: "MENU.USERMANAGEMENT",
    fontIcon: "bi-app-indicator",
    submenu: [
      {
        to: "/user",
        icon: "/media/icons/duotune/art/user.svg",
        title: "MENU.USER",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.ADD_USER,
        icon: "/media/icons/duotune/art/adduser.svg",
        title: "MENU.ADDUSER",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    to: "/queries",
    icon: "/media/icons/duotune/art/contant.svg",
    title: "MENU.CONTACTQURIES",
    fontIcon: "bi-app-indicator",
    submenu: [
      {
        to: "/queries/dev",
        icon: "/media/icons/duotune/art/contant.svg",
        title: "MENU.DEVEL0PERQURIES",
        fontIcon: "bi-app-indicator",
      },
      {
        to: "/queries/visitor",
        icon: "/media/icons/duotune/art/contant.svg",
        title: "MENU.VISITORQURIES",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    to: "/blog",
    icon: "/media/icons/duotune/art/blog.svg",
    title: "MENU.MANAGEBLOG",
    fontIcon: "bi-app-indicator",
    submenu: [
      {
        to: "/blog/add",
        icon: "/media/icons/duotune/art/blog.svg",
        title: "MENU.ADDBLOG",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    to: "/subscription",
    icon: "/media/icons/duotune/art/subscriptions.svg",
    title: "MENU.SUBSCRIPTIONMANAGEMENT",
    fontIcon: "bi-app-indicator",
  },
  {
    to: "/payment",
    icon: "/media/icons/duotune/art/payment.svg",
    title: "MENU.PAYMENTMANAGEMENT",
    fontIcon: "bi-app-indicator",
  },
  {
    to: "/document",
    icon: "/media/icons/duotune/art/docs.svg",
    title: "MENU.DOCUMENTMANAGEMENT",
    fontIcon: "bi-app-indicator",
    submenu: [
      {
        to: "/document",
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.DOCUMENT",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.APIREFERENCE,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.APIREFERENCE",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.DESIGN,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.DESIGN",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.SAMPLECODE,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.SAMPLECODE",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.SDKS,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.SDKs",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    to: "/profile",
    icon: "/media/icons/duotune/art/profile.svg",
    title: "MENU.MYACCOUNT",
    fontIcon: "bi-app-indicator",
  },
  {
    to: "/request",
    icon: "/media/icons/duotune/art/request.svg",
    title: "MENU.TALKTOUSREQUEST",
    fontIcon: "bi-app-indicator",
  },
  {
    to: "",
    icon: "/media/icons/duotune/art/docs.svg",
    title: "MENU.STATICPAGEMANAGEMENT",
    fontIcon: "bi-app-indicator",
    submenu: [
      {
        to: FrontEndRoutes.WHO_WE_ARE,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.WHOWEARE",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.ABOUT_US,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.ABOUTUS",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.CONTANT_US,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.CONTANTUS",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.TERM_AND_CONDITION,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.TERM&CONDITION",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.SKITTYBOP,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.SKITTYBOP",
        fontIcon: "bi-app-indicator",
      },
      {
        to: FrontEndRoutes.PRIVACYPOLICY,
        icon: "/media/icons/duotune/art/docs.svg",
        title: "MENU.PRIVACYPOLICY",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];
