import HttpClient from "./http-client";
export const PAYMENT_URL = `/transactions`;
export const PAYMENT_URL_BY_id = `/transactions/`;
export const USER_URL_DELETE = `/users/`;

export default class Payment {
  public static async viewPayment(key: string) {
    return HttpClient.get(PAYMENT_URL);
  }

  public static async viewPaymentById(id: any) {
    return HttpClient.get(PAYMENT_URL_BY_id + id);
  }

  public static async deletePayment(data: any) {
    console.log(data);
    return HttpClient.delete(USER_URL_DELETE + data.id);
  }
}
