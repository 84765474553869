import React, { FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
// import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { menu } from "../resources/core/config";
import { routes, IRoutes } from "./routes";
// import {MenuTestPage} from '../pages/MenuTestPage'
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";
import { WithChildren } from "../_metronic/helpers";
import ErrorBoundary from "../components/errorboundary/Errorboundry";
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue("--kt-primary");
    TopBarProgress.config({
      barColors: {
        "0": baseColor,
      },
      barThickness: 1,
      shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
  };

  const extractElement = (ReactElement: React.FC) => {
    return (
      <ErrorBoundary>
        <SuspensedView>
          <ReactElement />
        </SuspensedView>
      </ErrorBoundary>
    );
  };

  const setRoutes = (object: IRoutes, i: number) => {
    return Array.isArray(object.element) ? (
      <Route path={object.path} key={i}>
        {object.element.map((ele: IRoutes, index) => setRoutes(ele, index))}
      </Route>
    ) : (
      <Route
        key={i}
        path={object.path}
        element={extractElement(object.element)}
      />
    );
  };

  return (
    <Routes>
      <Route element={<MasterLayout sidebarMenu={menu} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}

        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}

        {routes && routes.map((ele: any, i) => setRoutes(ele, i))}

        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
