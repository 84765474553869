import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminServices from "../../../services/admin";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";

export interface ViewDetailsInterface {
  email: string;
  id: number;
  name: string;
  phoneno: string;
  status: string;
}
interface ViewUserState {
  data?: ViewDetailsInterface[] | any;
  loading: boolean;
  error?: string;
}

const initialState: ViewUserState = {
  data: undefined,
  loading: false,
  error: undefined,
};
// export interface IQuery {
//   name: string;
//   email: string;
//   mobile: string;
// }
export const viewUser = createAsyncThunk(
  "viewUser/viewUserDetails",
  async (query: string = "") => {
    try {
      const res = await AdminServices.viewUser(query);
      console.log(res);
      return res.data;
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "viewUser",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(viewUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewUser.fulfilled, (state, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(viewUser.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
