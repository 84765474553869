import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Payment from "../../../services/payment";
import error from "../../../utils/error";
// import LandingServices, { ILandingInfo } from "../../services/landing";
interface DeletePaymentState {
  isDeleted: boolean;
  loading: boolean;
  error?: string;
}

const initialState: DeletePaymentState = {
  isDeleted: false,
  loading: false,
  error: undefined,
};

interface IThunk {
  key: string;
  id: string;
}
export const deletePayment = createAsyncThunk(
  "deletePayment/deletePayment",
  async (data: IThunk) => {
    try {
      return await Payment.deletePayment(data);
    } catch (error_) {
      return error_;
    }
  }
);

const slice = createSlice({
  name: "deleteUser",
  initialState,
  reducers: {
    resetdeletePayment: (state) => {
      state.loading = false;
      state.isDeleted = false;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(deletePayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePayment.fulfilled, (state) => {
      state.loading = false;
      state.isDeleted = true;
    });
    builder.addCase(deletePayment.rejected, (state, action) => {
      state.loading = false;
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});
export const { resetdeletePayment } = slice.actions;
export default slice.reducer;
