import React, { useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../helpers";
import { useLayout } from "../../core";
import { useNavigate } from "react-router-dom";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [istemp, settemp] = useState(false);

  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div
      className={clsx(
        "menu-item",
        { "here ": isActive },
        { show: istemp },
        "menu-accordion"
      )}
      // data-kt-menu-trigger="click"
    >
      <span className="menu-link">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === "svg" && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && aside.menuIcon === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title" onClick={() => navigate(to)}>
          {title}
        </span>
        {/* <span className="menu-title" >{title}</span> */}
        <span
          className="menu-arrow  "
          style={{ height: "12px", width: "15px" }}
          onClick={() => settemp(!istemp)}
        ></span>
      </span>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
