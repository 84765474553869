/* eslint-disable react/jsx-no-undef */
import React, {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";

import { LayoutSplashScreen } from "../../../_metronic/layout/core";
import { AuthModel, UserModel } from "../../../resources/types/_models";
import * as authHelper from "../../../utils/auth-helpers";
import { getAdminByEmail } from "../../../services/_requests";
import { WithChildren } from "../../../_metronic/helpers";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());

  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const saveAuth = (auths: AuthModel | undefined) => {
    setAuth(auths);
    if (auths) {
      authHelper.setAuth(auths);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    saveAuth(undefined);
    // eslint-disable-next-line unicorn/no-useless-undefined
    setCurrentUser(undefined);
    // window.location.href = "/login";
    // window.location.replace("./login");
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (email: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getAdminByEmail(email);
          if (data) {
            setCurrentUser(data);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };
    const useremail = localStorage.getItem("email");
    if (useremail && localStorage.getItem("access_token")) {
      requestUser(useremail);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
